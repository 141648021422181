import Text from '@components/atoms/Text';
import useAsyncStore from '@hooks/store/useAsyncStore';
import cx from 'classnames';
import { useMemo } from 'react';
import { useMedia } from 'react-use';

import { BREAKPOINT_TABLET } from '../../../../constants';
import useCurrencyStore from '../../../../hooks/store/useCurrencyStore';
import useRoiStore from '../../../../hooks/store/useRoiStore';
import { toPrice } from '../../../../utils/numberUtils';
import BarChart, { HoverState } from '../../../BarChart';
import styles from './styles.module.scss';
import useMiniCalculations from './useMiniCalculations';

const chartLabels = ['Y1', 'Y2', 'Y3', 'Y4'];
const initialHover: HoverState = [0, 3];

interface Props {
  showSavingsAndCost?: boolean;
  blurred?: boolean;
}

const Savings = ({ showSavingsAndCost, blurred }: Props) => {
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  useMiniCalculations();
  const roi = useRoiStore();
  const currency = useAsyncStore(useCurrencyStore, state => state.currency);
  const datasets = useMemo(
    () => [
      {
        label: 'Dataset 1',
        data: [
          roi.savingsByYear.year1,
          roi.savingsByYear.year2,
          roi.savingsByYear.year3,
          roi.savingsByYear.year4
        ]
      }
    ],
    [roi]
  );

  return (
    <div className={cx(styles.priceOverview, blurred && styles.blurred)}>
      {!isNarrow && (
        <div>
          <Text variant="label" color="grey5">
            Your accumulated savings year by year
          </Text>
        </div>
      )}
      {!isNarrow && (
        <div className={styles.chartWrapper}>
          <BarChart
            labels={chartLabels}
            datasets={datasets}
            mode="grow-striped"
            initialHover={initialHover}
          />
        </div>
      )}
      <div className={styles.numbers}>
        <div className={styles.numbersPair}>
          <Text variant="label" color="grey5">
            Savings per year
          </Text>
          <div className={styles.annualSavings}>
            {toPrice(roi.annualSavings, 0, 1000, currency)}
          </div>
        </div>
        {showSavingsAndCost && (
          <>
            <div className={styles.numbersPair}>
              <Text variant="label" color="grey5">
                Savings per order
              </Text>
              <div className={styles.otherSavings}>
                {toPrice(roi.savingsPerOrder, 2, undefined, currency)}
              </div>
            </div>
            <div className={styles.numbersPair}>
              <Text variant="label" color="grey5">
                upfront costs
              </Text>
              <div className={styles.otherSavings}>
                {toPrice(roi.upfrontInvestment, 0, 1000, currency)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Savings;
