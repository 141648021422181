import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import useRoiStore from '../../../../hooks/store/useRoiStore';
import {
  getSuggestedBins,
  getSuggestedFteCost,
  getSuggestedFtes,
  getSuggestedOrderlinesB2b,
  getSuggestedOrderlinesB2c,
  getSuggestedWarehouseRent,
  getSuggestedWarehouseSize,
  initialInput,
  Input
} from '../../../../roi';
import { MiniCalculatorValues } from '.';

const useMiniCalculations = () => {
  const { values, validateForm } = useFormikContext<MiniCalculatorValues>();
  const recalculate = useRoiStore(state => state.calculate);

  useEffect(() => {
    validateForm({ ...values }).then(errors => {
      // We don't care if email is invalid here, so remove from a copy
      const errorsCopy = { ...errors };
      if (errorsCopy.companyEmail) {
        delete errorsCopy.companyEmail;
      }
      const isValid = Object.keys(errorsCopy).length === 0;
      if (!isValid) {
        return;
      }
      const annualGrowth = initialInput.annualGrowth / 100;
      const returnRate = initialInput.returnRate / 100;
      const b2bShare = initialInput.b2bShare / 100;
      const errorRate = initialInput.errorRate / 100;

      let input: Input = {
        industry: values.industry,
        dailyOrders: values.dailyOrders,
        fulfillmentType: values.fulfillmentType,
        merchantType: initialInput.merchantType,
        country: initialInput.country,
        annualGrowth,
        errorRate,
        bins: initialInput.bins,
        returnRate,
        b2bShare,
        orderlinesB2b: initialInput.orderlinesB2b,
        orderlinesB2c: initialInput.orderlinesB2c,
        fteCount: initialInput.fteCount,
        fteCost: getSuggestedFteCost(initialInput.country),
        warehouseHeight: initialInput.warehouseHeight,
        warehouseSize: initialInput.warehouseSize,
        warehouseRent: initialInput.warehouseRent
      };

      const suggestedOrderlinesB2b = getSuggestedOrderlinesB2b(values.industry);
      const suggestedOrderlinesB2c = getSuggestedOrderlinesB2c(values.industry);
      const suggestedBins = getSuggestedBins(
        values.industry,
        values.skuCount,
        input.merchantType
      );
      input = {
        ...input,
        orderlinesB2b: suggestedOrderlinesB2b,
        orderlinesB2c: suggestedOrderlinesB2c,
        bins: suggestedBins
      };
      const suggestedFTEs = getSuggestedFtes(
        input.b2bShare,
        input.dailyOrders,
        input.annualGrowth,
        input.orderlinesB2b,
        input.orderlinesB2c
      );
      const suggestedWarehouseSize = getSuggestedWarehouseSize(
        input.bins,
        input.warehouseHeight
      );
      input = {
        ...input,
        fteCount: suggestedFTEs,
        warehouseSize: suggestedWarehouseSize
      };
      const suggestedWarehouseRent = getSuggestedWarehouseRent(input.country);
      input = {
        ...input,
        warehouseRent: suggestedWarehouseRent
      };

      recalculate(input);
    });
  }, [
    values,
    values.industry,
    values.dailyOrders,
    values.fulfillmentType,
    values.skuCount,
    recalculate,
    validateForm
  ]);
};

export default useMiniCalculations;
