import Text from '@components/atoms/Text';
import { Column, Columns } from '@components/website/Columns';
import SectionWrap from '@components/website/modules/ModuleSection';
import * as gtm from '@helpers/gtm';
import { ModuleMiniCalculator as ModuleMiniCalculatorTypes } from '@sanity/sanity.types';
import * as Sentry from '@sentry/nextjs';
import cx from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import Link from 'next/link';
import { useState } from 'react';

import { fulfillmentTypeOptions } from '../../../../data/fulfillment';
import { industryOptions } from '../../../../data/industries';
import HubspotAPI from '../../../../hubspot/api';
import { CustomerData } from '../../../../hubspot/types';
import { BasicInput } from '../../../../roi';
import { DAILY_ORDER_MAX, DAILY_ORDER_MIN } from '../../../../roi/constants';
import { initialBasicInput } from '../../../../roi/data';
import { ModuleComponentType } from '../../../../types/module';
import {
  DAILY_ORDERS_MAX_MESSAGE,
  SKU_COUNT_MAX_MESSAGE
} from '../../../../validation/coreValidator';
import { basicRoiSchema } from '../../../../validation/roiValidator';
import Button from '../../../Button';
import { InputField, SelectField } from '../../../inputs';
import { Editorial, MediumTitle } from '../ModuleContent/Components';
import Savings from './Savings';
import styles from './styles.module.scss';

const getInputSubtext = (
  isError: boolean,
  isSuccess: boolean,
  isBlurred?: boolean
) => {
  if (!isSuccess && !isError && isBlurred) {
    return `Enter your email to unlock your results.`;
  }

  if (!isSuccess && !isError) {
    return `Fill in your email address and we'll reach out to you`;
  }

  if (isSuccess && !isError) {
    return `We'll be in touch soon!`;
  }
  if (!isSuccess && isError) {
    return `Sorry! Something went wrong... please try again`;
  }
};

export type MiniCalculatorValues = BasicInput &
  Pick<CustomerData, 'companyEmail'>;

const ModuleMiniCalculator: ModuleComponentType = ({ data }) => {
  const { header, description, leftLabel, initialBlurred } =
    data as unknown as ModuleMiniCalculatorTypes;

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleSubmit = async (
    values: MiniCalculatorValues,
    formikHelpers: FormikHelpers<MiniCalculatorValues>
  ) => {
    try {
      setIsError(false);
      await HubspotAPI.updateCustomerData(values.companyEmail, values);
      gtm.miniCalculatorSubmitted();
      setIsSuccess(true);
      setIsBlurred(false);
    } catch (error) {
      setIsError(true);
      Sentry.captureException(error);
    }
    formikHelpers.setSubmitting(false);
  };
  const [isBlurred, setIsBlurred] = useState<boolean>(initialBlurred);
  const [showInput, setShowInput] = useState<boolean>(initialBlurred);

  return (
    <SectionWrap className={styles.sectionWrap} id="section-savings">
      <Columns className={styles.columnPadding}>
        <Column start={1} span={4}>
          <MediumTitle text={leftLabel ?? ''} />
        </Column>
        <Column start={6} span={7}>
          <Editorial header={header} description={description} />
        </Column>
      </Columns>

      <Formik<MiniCalculatorValues>
        initialValues={{ companyEmail: '', ...initialBasicInput }}
        initialTouched={{
          skuCount: true,
          dailyOrders: true,
          fulfillmentType: true,
          industry: true,
          companyEmail: false
        }}
        onSubmit={handleSubmit}
        validationSchema={basicRoiSchema}
      >
        {formik => (
          <Form id="mini_calculator_form">
            <Columns
              className={cx(styles.columnPadding, styles.bottomColumnsPadding)}
            >
              <Column start={1} span={4}>
                <div className={styles.inputs}>
                  <SelectField
                    inputId="industry-field"
                    name="industry"
                    label="Your business"
                    options={industryOptions}
                  />
                  <InputField
                    id="sku-count-field"
                    name="skuCount"
                    type="number"
                    label="Number of SKUs"
                  />
                  <InputField
                    id="daily-orders-field"
                    name="dailyOrders"
                    type="number"
                    label="Daily orders"
                    min={DAILY_ORDER_MIN}
                    max={DAILY_ORDER_MAX}
                  />
                  <SelectField
                    inputId="fulfillment-type-field"
                    name="fulfillmentType"
                    options={fulfillmentTypeOptions}
                    label="Your current fulfillment"
                  />
                  {(formik.errors.dailyOrders === DAILY_ORDERS_MAX_MESSAGE ||
                    formik.errors.skuCount === SKU_COUNT_MAX_MESSAGE) && (
                    <div className={styles.errorBlurb}>
                      <Text variant="b2" color="navy">
                        Oops...this is a bit too much for our calculator to
                        handle, but not Pio!{' '}
                        <Text className={styles.link} as="span" variant="b2">
                          <Link href="/contact">Contact us</Link>
                        </Text>{' '}
                        for a customized savings estimate.
                      </Text>
                    </div>
                  )}
                </div>
              </Column>
              <Column className={styles.savingsColumn} start={6} span={7}>
                <Savings showSavingsAndCost={!isBlurred} blurred={isBlurred} />
                <div>
                  {showInput ? (
                    <div
                      className={cx(styles.emailInputWrapper, {
                        [styles.formDisabled]: isSuccess
                      })}
                    >
                      <Text
                        className={styles.inputText}
                        variant="b2"
                        color="grey5"
                      >
                        {getInputSubtext(isError, isSuccess, initialBlurred)}
                      </Text>
                      <InputField
                        type="email"
                        name="companyEmail"
                        placeholder="Company email"
                        showErrorIfExists
                        wrapperClassName={styles.emailInput}
                        submitButton={
                          <Button
                            variant="iconOnlyMain"
                            type="submit"
                            isLoading={formik.isSubmitting}
                            disabled={formik.isSubmitting || isSuccess}
                          />
                        }
                      />
                    </div>
                  ) : (
                    <Button
                      onClick={() => setShowInput(true)}
                      variant="smallMain"
                      type="button"
                    >
                      {`Let's talk`}
                    </Button>
                  )}
                </div>
              </Column>
            </Columns>
          </Form>
        )}
      </Formik>
    </SectionWrap>
  );
};

export default ModuleMiniCalculator;
